import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

const SEO = ({
	description,
	lang,
	meta,
	keywords,
	title
}) => {
	return (
		<StaticQuery
			query={detailsQuery}
			render={data => {
				const metaDescription =
					description || data.site.siteMetadata.description
				return (
					<Helmet
						htmlAttributes={{
							lang,
						}}
						title={`Kine+ Kids Oostrozebeke | ` + title}
						meta={[
							{
								name: `description`,
								content: metaDescription,
							},
							{
								property: `og:title`,
								content: `Kine+ Kids | ` + title,
							},
							{
								property: `og:description`,
								content: metaDescription,
							},
							{
								property: `og:type`,
								content: `website`,
							},
							{
								name: `twitter:card`,
								content: `summary`,
							},
							{
								name: `twitter:creator`,
								content: data.site.siteMetadata.author,
							},
							{
								name: `twitter:title`,
								content: title,
							},
							{
								name: `twitter:description`,
								content: metaDescription,
							},
							...meta,
							...(keywords.length > 0 ? {
								name: `keywords`,
								content: keywords.join(`, `),
							} : {}),
						]}
					/>
				)
			}}
		/>
	)
}

SEO.defaultProps = {
	lang: `nl`,
	meta: [],
	keywords: [
		"kinderkine",
		"kinderkinesitherapie",
		"kinesitherapie voor kinderen",
		"typen",
		"typ10",
		"blind typen",
		"blind leren typen",
		"Desselgem",
		"Ooigem",
		"Izegem",
		"Ingelmunster",
		"Wielsbeke",
		"Waregem",
		"Zulte",
		"Dentergem",
		"Tielt",
		"Wakken",
		"Meulebeke",
		"Marialoop",
		"Sint-Eloois-Vijve",
		"Sint-Baafs-Vijve",
		"Harelbeke",
		"Beveren-Leie",
		"Deerlijk",
		"Lendelede",
		"Oostrozebeke",
	],
	title: null,
	description: null,
}

SEO.propTypes = {
	description: PropTypes.string,
	lang: PropTypes.string,
	meta: PropTypes.array,
	keywords: PropTypes.arrayOf(PropTypes.string),
	title: PropTypes.string.isRequired,
}

export default SEO

const detailsQuery = graphql`
	query DefaultSEOQuery {
		site {
			siteMetadata {
				title
				description
				author
			}
		}
	}
`

import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'

import headerStyles from '../css/header.module.css'

const Header = () => {
	const [breakpoint, setBreakpoint] = useState(false)

	useEffect(() => {
		if (window.innerWidth < 960) {
			setBreakpoint(true)
		} else {
			setBreakpoint(false)
		}
	})

	return (
		<div className={headerStyles.fixedContainer}>
			<div className={headerStyles.container}>
				<Link className={headerStyles.h1} to="/">
					Kine<span className={headerStyles.plus}>+</span> Kids
				</Link>
				<nav className={headerStyles.navContainer}>
					<ul className={headerStyles.navList}>
						<li>
							<Link
								to="/"
								className={headerStyles.navItem}
								activeClassName={headerStyles.navBorder}
							>
								WELKOM
							</Link>
						</li>
						<li>
							<Link
								to="/aanbod"
								className={headerStyles.navItem}
								activeClassName={headerStyles.navBorder}
								partiallyActive={true}
							>
								AANBOD
							</Link>
						</li>
						<li>
							<Link
								to="/werkwijze"
								className={headerStyles.navItem}
								activeClassName={headerStyles.navBorder}
							>
								WERKWIJZE
							</Link>
						</li>
						<li className={headerStyles.specialNavItem}>
							<Link
								to="/contact"
								className={headerStyles.navItem}
								activeClassName={
									breakpoint ? headerStyles.navBorder : ''
								}
							>
								CONTACT
							</Link>
						</li>
					</ul>
				</nav>
			</div>
		</div>
	)
}

export default Header

import React from 'react'
import PropTypes from 'prop-types'
// import { StaticQuery, graphql } from 'gatsby'

import Header from './header'
import Footer from './footer'
import '../css/global.css'

const Layout = ({ children }) => (
	<div>
		<Header />
		<div className="contentContainer">{children}</div>
		<Footer />
	</div>
)

Layout.propTypes = {
	children: PropTypes.node.isRequired,
}

export default Layout

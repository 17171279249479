import { Link } from 'gatsby'
import React from 'react'

import footerStyles from '../css/footer.module.css'
import fb from '../images/facebook.svg'

const Footer = () => (
	<div className={footerStyles.container}>
		<Link to="/" className={footerStyles.title}>
			Kine<span className={footerStyles.plus}>+</span> Kids
		</Link>
		<nav className={footerStyles.navContainer}>
			<ul className={footerStyles.navList}>
				<li>
					<Link to="/" className={footerStyles.navItem}>
						WELKOM
					</Link>
				</li>
				<li>
					<Link to="/aanbod" className={footerStyles.navItem}>
						AANBOD
					</Link>
				</li>
				<li>
					<Link to="/werkwijze" className={footerStyles.navItem}>
						WERKWIJZE
					</Link>
				</li>
				<li>
					<Link to="/contact" className={footerStyles.navItem}>
						CONTACT
					</Link>
				</li>
			</ul>
		</nav>
		<address className={footerStyles.contactInfo}>
			<p
				className={
					footerStyles.adresLine + ' ' + footerStyles.contactLine
				}
			>
				Hoogstraat 109 B 8780 Oostrozebeke
			</p>
			<p className={footerStyles.contactLine}>0499/33.68.74</p>
			<a
				href="mailto:kinepluskids@gmail.com"
				className={footerStyles.contactLine}
				style={{ textDecoration: `underline`, color: `#FFFFFF` }}
			>
				kinepluskids@gmail.com
			</a>
		</address>
		<a
			href="https://www.facebook.com/kinepluskids/"
			target="_blank"
			rel="noopener noreferrer"
			className={footerStyles.fb}
		>
			<img src={fb} alt="facebook logo" width="55" height="55"></img>
		</a>
		<div className={footerStyles.line}></div>
		<p className={footerStyles.copyright}>
			© {new Date().getFullYear()} kinepluskids.be All Rights Reserved
		</p>
	</div>
)

export default Footer

import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/SEO'

import werkwijzeStyles from '../css/werkwijze.module.css'

const Werkwijze = () => (
	<Layout>
		<SEO title="Werkwijze" />
		<h1>Werkwijze</h1>
		<p className={werkwijzeStyles.intro}>
			Het therapieverloop gebeurt steeds op dezelfde wijze:
		</p>
		<div className={werkwijzeStyles.container}>
			<div className={werkwijzeStyles.stepItem}>
				<p className={werkwijzeStyles.stepNumber}>1</p>
				<div>
					<h2 className={werkwijzeStyles.stepTitle}>
						Telefonisch contact
					</h2>
					<p className={werkwijzeStyles.stepContent}>
						Gelieve steeds telefonisch contact op te nemen.
					</p>
				</div>
			</div>
			<div className={werkwijzeStyles.stepItem}>
				<p className={werkwijzeStyles.stepNumber}>2</p>
				<div>
					<h2 className={werkwijzeStyles.stepTitle}>Intakegesprek</h2>
					<p className={werkwijzeStyles.stepContent}>
						Tijdens een eerste afspraak worden administratieve
						gegevens, hulpvraag, bezorgdheden… bevraagd en
						besproken.
					</p>
				</div>
			</div>
			<div className={werkwijzeStyles.stepItem}>
				<p className={werkwijzeStyles.stepNumber}>3</p>
				<div>
					<h2 className={werkwijzeStyles.stepTitle}>
						Inspectie en motorische evaluatie
					</h2>
					<p className={werkwijzeStyles.stepContent}>
						Tijdens de eerste afspraak wordt het kind motorische
						geobserveerd of onderzocht.
					</p>
				</div>
			</div>
			<div className={werkwijzeStyles.stepItem}>
				<p className={werkwijzeStyles.stepNumber}>4</p>
				<div>
					<h2 className={werkwijzeStyles.stepTitle}>
						Bepalen van behandelingsfrequentie
					</h2>
					<p className={werkwijzeStyles.stepContent}>
						Vaak schreef de dokter reeds de behandelfrequentie voor.
						Afhankelijk van de hulpvraag en motorische evaluatie
						wordt de therapienoodzaak verder besproken. We werken
						meestal met vaste afspraken dus wordt er gezocht naar
						een therapiemoment dat past bij uw voorkeur.
					</p>
				</div>
			</div>
			<div className={werkwijzeStyles.stepItem}>
				<p className={werkwijzeStyles.stepNumber}>5</p>
				<div>
					<h2 className={werkwijzeStyles.stepTitle}>
						Ouderbegeleiding
					</h2>
					<p className={werkwijzeStyles.stepContent}>
						Tijdens de behandeling van baby’s worden ouders
						gestimuleerd therapie mee te volgen. Dit om de tips die
						in de praktijk worden gegeven mee te nemen naar huis en
						daar toe te passen. Bij oudere kinderen merken we dat
						therapie vaak vlotter verloopt wanneer de ouders niet
						aanwezig zijn (zijn minder afgeleid…). Na de
						therapiesessie kunnen de oefeningen en aandachtspunten
						wel kort worden besproken.
					</p>
				</div>
			</div>
			<div className={werkwijzeStyles.stepItem}>
				<p className={werkwijzeStyles.stepNumber}>6</p>
				<div>
					<h2 className={werkwijzeStyles.stepTitle}>Verslaggeving</h2>
					<p className={werkwijzeStyles.stepContent}>
						Op vraag van de dokter, school… kan na verloop van tijd
						een verslag worden gemaakt. Hierbij worden de
						behandeldoelen en evolutie besproken.
					</p>
				</div>
			</div>
			<div className={werkwijzeStyles.stepItem}>
				<p className={werkwijzeStyles.stepNumber}>7</p>
				<div>
					<h2 className={werkwijzeStyles.stepTitle}>Herevaluatie</h2>
					<p className={werkwijzeStyles.stepContent}>
						Regelmatig worden de behandeldoelen geëvalueerd, al dan
						niet via motorische testing. De behandelnoodzaak kan
						worden aangepast.
					</p>
				</div>
			</div>
			<div className={werkwijzeStyles.stepItem}>
				<p className={werkwijzeStyles.stepNumber}>8</p>
				<div>
					<h2 className={werkwijzeStyles.stepTitle}>
						Overleg: MDO, onderzoek…
					</h2>
					<p className={werkwijzeStyles.stepContent}>
						Indien gewenst kunnen wij op uw vraag aanwezig zijn bij
						schoolcontacten, onderzoeken…
					</p>
				</div>
			</div>
		</div>
	</Layout>
)

export default Werkwijze
